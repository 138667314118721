import React from 'react'
import Logo from '../../assets/logo.png';

export default function ComingSoon() {
          return (
                    <div className="w-full h-screen">
                              <div className="w-full h-screen flex flex-col items-center justify-between bg-black bg-opacity-30 py-8">
                                        <div className="flex-1 flex flex-col items-center justify-center">
                                                  <div className="bg-white bg-opacity-10 px-4 py-2 rounded-xl flex items-center justify-center text-cyan-100 space-x-2 lg:space-x-4">
                                                            <img src={Logo} alt="Mirror-LTD Music" className="w-8 lg:w-10" />
                                                            <span className="text-xl lg:text-2xl xl:text-3xl font-bold">Mirror-LTD Music</span>
                                                  </div>
                                                  <h1 className="text-6xl lg:text-7xl xl:text-8xl text-gray-200 tracking-wider font-bold font-serif mt-12 text-center">Coming Soon</h1>
                                        </div>
                              </div>
                    </div>
          )
}
