import React from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { BASE_API } from '../../config';
import useTitle from '../../hooks/useTitle';
import { AiOutlineUser, AiOutlinePhone } from 'react-icons/ai'
import { IoMdMusicalNote } from 'react-icons/io'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import axios from 'axios';
import Login from '../../assets/login.png'
import { Link } from 'react-router-dom';

export default function Apply() {
          useTitle("Apply");

          const handleSubmit = (e: any) => {
                    e.preventDefault();

                    const form = e.target as typeof e.target & {
                              firstName: { value: string };
                              lastName: { value: string };
                              artistName: { value: string };
                              email: { value: string };
                              phoneNumber: { value: string };
                              artistBio: { value: string };
                              terms: { value: string };
                    };

                    const firstName = form.firstName.value;
                    const lastName = form.lastName.value;
                    const artistName = form.artistName.value;
                    const email = form.email.value;
                    const phoneNumber = form.phoneNumber.value;
                    const artistBio = form.artistBio.value;
                    const terms = form.terms.checked;

                    const data = {
                              email,
                              firstName,
                              lastName,
                              phoneNumber,
                              artistName,
                              artistBio,
                    };

                    if (!firstName) {
                              toast.error('Please enter your first name');
                              return;
                    } else if (!lastName) {
                              toast.error('Please enter your last name');
                              return;
                    } else if (!artistName) {
                              toast.error('Please enter your artist name');
                              return;
                    } else if (!email) {
                              toast.error('Please enter your email');
                              return;
                    } else if (!phoneNumber) {
                              toast.error('Please enter your phone number');
                              return;
                    } else if (!form.artistBio.value) {
                              toast.error('Please tell us about yourself')
                    } else if (phoneNumber.length !== 11) {
                              toast.error('Please enter a valid phone number');
                              return;
                    } else {
                              if (!terms) {
                                        toast.error('Please agree to our terms of service');
                                        return;
                              } else {
                                        axios.post(`${BASE_API}/waiting-users/signup`, data)
                                                  .then((res) => {
                                                            if (res.status === 201) {
                                                                      Swal.fire({
                                                                                title: "Success!",
                                                                                text: "Your application has been submitted",
                                                                                icon: "success",
                                                                                showConfirmButton: false,
                                                                                timer: 3000,
                                                                      });
                                                            } else {
                                                                      Swal.fire({
                                                                                title: "Error!",
                                                                                text: res.data.message,
                                                                                icon: "error",
                                                                                showConfirmButton: false,
                                                                                timer: 3000,
                                                                      });
                                                            }
                                                            form.reset();
                                                  })
                              }
                    }
          }

          return (
                    <div className="min-h-screen bg-white md:bg-gradient-to-br from-[#98fff6] to-[#ffb5b5] flex items-center justify-center px-2 md:px-5 py-5">
                              <div className="md:bg-gray-100 text-gray-500 md:rounded-3xl md:shadow-md w-full overflow-hidden" style={{ maxWidth: '1200px' }}>
                                        <div className="flex justify-center items-center w-full">
                                                  <div className="hidden md:block w-1/2 lg:px-10">
                                                            <img src={Login} alt="Mirror-LTD Music" className='w-full object-cover' />
                                                  </div>
                                                  <div className="w-full md:w-1/2 py-5 md:py-10 px-5 md:px-10">
                                                            <div className="text-center mb-5 md:mb-10">
                                                                      <h1 className="font-bold text-3xl text-gray-900">Apply for an artist</h1>
                                                                      <p className='mt-2'>Enter your details below to apply for a <Link to={"/"} className='text-error duration-300'>Mirror LTD</Link> account</p>
                                                            </div>
                                                            <form onSubmit={handleSubmit}>
                                                                      <div className="flex flex-col md:flex-row -mx-3">
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">First name <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineUser /></div>
                                                                                                    <input type="text" name='firstName' className="w-full -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="First Name" />
                                                                                          </div>
                                                                                </div>
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">Last name <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineUser /></div>
                                                                                                    <input type="text" name='lastName' className="w-full -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="Last Name" />
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                                      <div className="flex -mx-3">
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">Artist Name <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><IoMdMusicalNote /></div>
                                                                                                    <input type="text" name='artistName' className="w-full -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="Your artist name" />
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                                      <div className="flex -mx-3">
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">Email <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><MdOutlineAlternateEmail /></div>
                                                                                                    <input type="email" name='email' className="w-full -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="Your email" />
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                                      <div className="flex -mx-3">
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">Phone Number <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlinePhone /></div>
                                                                                                    <input type="number" name='phoneNumber' className="w-full -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="Phone number" />
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                                      <div className="flex -mx-3">
                                                                                <div className="w-full px-3 mb-3 md:mb-5">
                                                                                          <label htmlFor="" className="text-xs font-semibold px-1">Tell us about yourself <span className='text-red-500'>*</span></label>
                                                                                          <div className="flex">
                                                                                                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><AiOutlineUser /></div>
                                                                                                    <textarea typeof="text" style={{ resize: 'none' }} name='artistBio' className="w-full h-[200px] md:h-[150px] -ml-10 pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-error" placeholder="About yourself" />
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                                      <div className='flex items-start gap-2 mb-5'>
                                                                                <input type="checkbox" name='terms' className="checkbox checkbox-xs" />
                                                                                <p className='text-xs text-gray-500'>I have read and agree to Mirror LTD's <a href="/" className='text-error'>Terms of services <span className='text-red-500'>*</span></a></p>
                                                                      </div>
                                                                      <div className="flex -mx-3 mb-5">
                                                                                <div className="w-full px-3">
                                                                                          <button className="block btn btn-sm md:btn-md md:px-6 btn-error mx-auto text-white font-semibold">Submit</button>
                                                                                </div>
                                                                      </div>
                                                                      <div className='text-center text-sm'>
                                                                                <p className='text-gray-500'>Already have an account? <a href="https://music.mirrorltd.com" className='text-error'>Login</a></p>
                                                                      </div>
                                                            </form>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          )
}
