import React from 'react'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import useTitle from '../../hooks/useTitle';

export default function ContactSignUp() {
  useTitle("Contact Sign Up");

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const form = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };
    const name = form.name.value;
    const email = form.email.value;
    const message = form.message.value;

    if (name === '' || email === '' || message === '') {
      toast.error('Please fill in all fields');
      return;
    } else {
      const data = {
        name,
        email,
        message
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to submit this form?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          emailjs.send('gmailSignUp', 'template_0vnk8to', {
            from_name: data.name,
            from_email: data.email,
            message: data.message
          }, 'jlXCBkGV7oc3GusDz')
            .then(function (res: any) {
              Swal.fire({
                title: 'Success!',
                text: 'Your form has been submitted.',
                icon: 'success',
                confirmButtonText: 'Ok, thanks!'
              })
              form.name.value = '';
              form.email.value = '';
              form.message.value = '';
            }, function (err: any) {
              console.log('FAILED...', err);
            });
        }
      }
      )
    }
  }

  return (
    <div className={`bg-[url('./assets/bg.jpg')] bg-cover`}>
      <div className='flex flex-col justify-center items-center h-screen'>
        <div className='glass p-5 rounded-xl w-full md:w-7/12 lg:w-1/3'>
          <Link to='/' className='btn btn-xs glass absolute top-2 left-2'><i className='bx bx-left-arrow-alt' ></i>Back</Link>
          <div className='mb-6 md:mb-10 text-center text-white'>
            <h1 className='text-4xl'>Sign Up</h1>
            <p className='text-xl'>Fill in the from below and submit it</p>
          </div>
          <form onSubmit={handleSubmit} className='w-full'>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text text-white">Type Your Name</span>
              </label>
              <input type="text" placeholder="Your Name" name='name' className="input input-bordered bg-transparent text-white placeholder:text-gray-200 border-white w-full" />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text text-white">Type Your Email</span>
              </label>
              <input type="email" placeholder="Your Email" name='email' className="input input-bordered bg-transparent text-white placeholder:text-gray-200 border-white w-full" />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text text-white">Type Your Message</span>
              </label>
              <textarea className="textarea textarea-bordered bg-transparent resize-none text-white placeholder:text-gray-200 border-white h-48" name='message' placeholder="Your Message" />
            </div>
            <button className='btn glass mt-5'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}
