import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Root from './Layouts/Root';
import ContactSignUp from './pages/ContactSignUp/ContactSignUp';
import ContactTakeDown from './pages/ContactTakeDown/ContactTakeDown';
import Apply from './pages/Apply/Apply';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />
    },
    {
      path: '/apply',
      element: <Apply />
    },
    {
      path: '/contactSignUp',
      element: <ContactSignUp />
    },
    {
      path: '/contactTakeDown',
      element: <ContactTakeDown />
    },
  ]
);

function App() {
  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  // useEffect(() => {
  //   const disableRightClick = (e: any) => {
  //     if (e.button === 2) {
  //       e.preventDefault()
  //     } else if (e.button === 0) {
  //       e.preventDefault()
  //     }
  //   }
  //   document.addEventListener("contextmenu", disableRightClick, { passive: false })
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick)
  //   }
  // }, [])

  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

export default App;
