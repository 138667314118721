import React from 'react'
import { Outlet } from 'react-router-dom';
import useTitle from '../hooks/useTitle';
import ComingSoon from '../pages/ComingSoon/ComingSoon';

export default function Root() {
  useTitle("Coming Soon");
  return (
    <div className={`bg-[url('./assets/bg.jpg')] bg-cover`}>
      <ComingSoon />
      <Outlet />
    </div>
  )
}
